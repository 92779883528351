<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="title-blue h1">Finding Out Your Cloud Policy</h2>
    <p class="my-5">
      We will help you in defining the cloud policy that best serves your
      requirements and make sure you get the most out of this strategy.
    </p>
  </div>
</template>

<script>
export default {
  name: "third-comp-section",
};
</script>
