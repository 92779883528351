<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Want to shift toward Cloud?</h2>
      <p class="desc">
        We are providers of a vast range of services, including composite
        solutions in either your or our data centers, that consist of combining
        data and cloud storage solutions to your devices.
      </p>
      <p class="desc">
        We allow you to experience the best of all IT environments for which we
        provide you access to all regional, provincial, or national cloud
        carriers along with access to distant offices, regional, provincial or
        national offsite locations or national and international cloud servers.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/cloud-computing/cloud-computing.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-comp-section",
};
</script>

<style></style>
