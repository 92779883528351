<template>
  <cloud-computing />
</template>

<script>
import CloudComputing from "../../components/solutions/data-center/CloudComputing.vue";
export default {
  components: { CloudComputing },
  name: "cloud-computing-page",
};
</script>
