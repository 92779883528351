<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="advantages h1">Advantages of Cloud Solutions</h2>
    <p class="my-2">
      By using cloud computing solutions, you will spend less and get more.
      Cloud solutions are mainly used because they allow a cut down of the
      expenditure. it also, it increases the effectiveness, coherency,
      dexterity, safety and faster acquisition of services.
    </p>
  </div>
</template>

<script>
export default {
  name: "fivth-comp-section",
};
</script>
