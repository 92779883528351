<template>
  <div class="modifying mt-5 pt-5 text-center">
    <ul class="row">
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-maximize fs-1 icon"></i>
        <h4 class="mt-5 h1 title-yellow">Scalability</h4>
        <p>
          Easily expand your IT capacity and functionality without buying
          hardware and facility space.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-lock fs-1 icon"></i>
        <h4 class="mt-5 h1 title-yellow">Security</h4>
        <p>
          Your business vital system and data will be protected from loss and
          even theft.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-shuffle fs-1 icon"></i>
        <h4 class="mt-5 h1 title-yellow">Flexibility and Access</h4>
        <p>
          Give your users greater flexibility to run apps and access data
          whenever and wherever needed.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-regular fa-clock fs-1 icon"></i>
        <h4 class="mt-5 h1 title-yellow">Speed</h4>
        <p>Providing new services and functionality in a matter of hours</p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-umbrella fs-1 icon"></i>
        <h4 class="mt-5 h1 title-yellow">Data Protection</h4>
        <p>
          You’ll never have to worry about losing your data in the event of any
          disaster.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-dollar-sign fs-1 icon"></i>
        <h4 class="mt-5 h1 title-yellow">Cost Management</h4>
        <p>Pay only for the capacity you use.</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "fourth-comp-section",
};
</script>

<style></style>
